// 组件
// import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { putZooTicketsFormData, getSiteCollection, getTicketPreview } from '@/services/zoo-tickets.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    // importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      urlParams: {},
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.urlParams = this.$route.params
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getTicketPreview(this.urlParams.id).then((res) => {
        this.modifyData = res.data.ticket_package
      })
      getSiteCollection().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[4].options = res.data.collections.sites
          this.formData[0].createDateItem[5].options = res.data.collections.sites
          this.formData[0].createDateItem[3].options = res.data.collections.package_types
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop, item, creatData) {
      if (item.prop === 'package_type') {
        creatData.site_id = ''
        creatData.related_site_ids = []
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        ticket_package: obj
      }
      params.ticket_package.remote_image_url = params.ticket_package.image_url
      delete params.ticket_package.image_url
      // params.site_label.remote_image_url = params.site_label.image
      // delete params.site_label.image
      // Object.keys(params.site_label).forEach(res => {
      //   if (typeof params.site_label[res] === 'boolean') {
      //     if (params.site_label[res] === true) params.site_label[res] = '1'
      //     if (params.site_label[res] === false) params.site_label[res] = '0'
      //   }
      // })
      putZooTicketsFormData(this.urlParams.id, params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'zooTicketsList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
